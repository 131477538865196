@import url('https://fonts.googleapis.com/css2?family=Biryani:wght@800&family=Bree+Serif&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  scroll-behavior: smooth;
  color: white;

  --global-drop-shadow: drop-shadow(4px -2px 3px black);
  --global-shadow: 0 4px 12px 3px rgba(0,0,0,.5);
}